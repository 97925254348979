<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Dokümanlar</b-card-title>
    </b-card-header>
    <b-table
      v-if="dataList.length > 0"
      class="mt-1"
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(document_type)="data">
        {{ data.item.document_type }}
        <div
          v-if="data.item.sdate"
          class="text-warning font-small-2"
        >
          {{ moment(data.item.sdate).format('DD.MM.YYYY') }} -
          {{ moment(data.item.edate).format('DD.MM.YYYY') }}
        </div>
        <div
          v-if="data.item.customer_name"
          class="text-primary font-small-2"
        >
          <div v-if="data.item.company_name">
            {{ data.item.company_name }}
            <div class="text-muted">
              {{ data.item.customer_name }}
            </div>
          </div>
          <div v-else>
            {{ data.item.customer_name }}
          </div>
        </div>
        <div class="text-muted font-small-2">
          {{ moment(data.item.created).format('ll') }} tarihinde {{ data.item.username }} tarafından yüklendi.
        </div>
      </template>
      <template #cell(filename)="data">
        <b-button
          variant="primary"
          :href="baseURL + data.item.filename"
          target="_blank"
          size="sm"
        >
          <FeatherIcon icon="DownloadIcon" />
          İndir
        </b-button>
        <b-button
          variant="flat-danger"
          size="sm"
          @click="removeData(data.item.id)"
        >
          <FeatherIcon icon="TrashIcon" />
          Sil
        </b-button>
      </template>
    </b-table>
    <b-card-body v-else>
      <b-alert show>
        <div class="alert-body text-center">
          Araç kartı için doküman bulunamadı.
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BAlert, BButton, BCard, BCardBody, BCardHeader, BCardTitle, BTable,
} from 'bootstrap-vue'

export default {
  name: 'Documents',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BTable,
    BButton,
    BAlert,
  },
  data() {
    return {
      baseURL: `${this.$store.state.app.baseURL}/media/rentals/`,
      fields: [
        {
          key: 'document_type',
          label: 'Doküman',
        },
        {
          key: 'filename',
          label: '',
          thClass: 'width-200',
          tdClass: 'text-right',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalDocuments/dataList']
    },
  },
  methods: {
    removeData(id) {
      this.$swal({
        title: this.$store.state.app.removeTitle,
        text: this.$store.state.app.removeMessage,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('rentalDocuments/removeData', id)
          this.$swal({
            icon: 'success',
            title: this.$store.state.app.removeResultTitle,
            text: this.$store.state.app.removeResultMessage,
            confirmButtonText: this.$store.state.app.removeResultClose,
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.getDataList()
        }
      })
    },
  },
}
</script>
