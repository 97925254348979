<template>
  <div>
    <div
      v-if="dataItem.file"
      class="mb-1"
    >
      <b-button
        variant="success"
        size="sm"
        :href="baseURL + '/media/rentals/' + dataItem.file"
        target="_blank"
      >
        <FeatherIcon
          icon="DownloadIcon"
        />
        Sözleşme İndir
      </b-button>
    </div>
    <b-form-group
      v-else
      label="Sözleşme Dosyası"
      label-for="upload_file"
    >
      <b-form-file
        v-model="dataItem.upload_file"
        placeholder="Bir dosya seçin veya buraya bırakın..."
        drop-placeholder="Dosya buraya bırakın..."
        browse-text="Dosya Seçin"
      />
    </b-form-group>
  </div>
</template>

<script>
import {
  BFormGroup, BFormFile, BButton,
} from 'bootstrap-vue'

export default {
  name: 'UploadFile',
  components: {
    BFormGroup,
    BFormFile,
    BButton,
  },
  data() {
    return {
      baseURL: this.$store.state.app.baseURL,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
  },
}
</script>
