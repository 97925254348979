<template>
  <div>
    <b-card-body v-if="!dataItem.rental_stocks.length">
      <b-alert show>
        <div class="alert-body text-center">
          Sözleşmeye bağlı araç bulunmamaktadır.
        </div>
      </b-alert>
    </b-card-body>
    <b-table
      v-else
      responsive="sm"
      :fields="fields"
      :items="dataItem.rental_stocks"
      striped
    >
      <template #cell(brand)="data">
        <div class="text-warning font-small-2">
          {{ data.item.arackodu }}
        </div>
        {{ data.item.brand }} {{ data.item.model }}
        <div class="text-primary font-small-2">
          {{ data.item.title }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.fuel }} {{ data.item.gear }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.licence_plate }}
        </div>
        <div class="mt-1">
          <b-button
            variant="info"
            size="sm"
            target="_blank"
            :to="'/rental/stocks/edit/' + data.item.id"
          >
            Araç Kartı Güncelle
          </b-button>
        </div>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="flat-danger"
            :disabled="dataItem.rental_stocks.includes(data.item.id)"
            pill
            class="btn-icon"
            @click="removeStockData(data.item.id)"
          >
            <FeatherIcon icon="XCircleIcon" />
          </b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable, BButton, BCardBody, BAlert,
} from 'bootstrap-vue'

export default {
  name: 'SelectedStocks',
  components: {
    BTable,
    BButton,
    BCardBody,
    BAlert,
  },
  data() {
    return {
      fields: [
        {
          key: 'brand',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
  },
  methods: {
    removeStockData(id) {
      const itemKey1 = this.dataItem.rental_stocks.findIndex(e => e.id === id)
      const itemKey2 = this.dataItem.id_com_rental_stocks.findIndex(e => e === id)
      this.dataItem.rental_stocks.splice(itemKey1, 1)
      this.dataItem.id_com_rental_stocks.splice(itemKey2, 1)
    },
  },
}
</script>
