<template>
  <div>
    <b-tabs
      pills
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="FileIcon" />
          <span>Sözleşme</span>
        </template>
        <contract-form :is-update="true" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="ArchiveIcon" />
          <span>Dokümanlar</span>
        </template>
        <documents />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="UserIcon" />
          <span>Müşteri Kartı</span>
        </template>
        <customer />
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BTabs, BTab,
} from 'bootstrap-vue'
import Customer from '@/views/Crm/View/Customer.vue'
import ContractForm from '@/views/Rental/Contracts/ContractForm.vue'
import Documents from '@/views/Rental/Contracts/elements/Documents.vue'

export default {
  name: 'Edit',
  components: {
    BTabs,
    BTab,
    Customer,
    ContractForm,
    Documents,
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getData() {
      this.$store.dispatch('rentalContracts/getDataItem', this.$route.params.id)
        .then(res => {
          if (res.id_com_customer) {
            this.$store.dispatch('customers/customerView', res.id_com_customer)
          }
          if (res.id_com_rental_stocks) {
            this.$store.dispatch('rentalStocks/getDataList', {
              where: {
                'com_rental_stocks.id': res.id_com_rental_stocks,
              },
            })
            this.$store.dispatch('rentalDocuments/getDataList', {
              where: {
                'com_rental_documents.id_com_rental_stocks': res.id_com_rental_stocks,
              },
            })
          }
        })
    },
  },
}
</script>
