<template>
  <b-form-group
    label="Sözleşme Tarihi"
    label-for="contract_date"
  >
    <validation-provider
      #default="{ errors }"
      name="Sözleşme Tarihi"
      rules="required"
    >
      <b-form-datepicker
        id="contract_date"
        v-model="dataItem.contract_date"
        v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
        locale="tr"
        start-weekday="1"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormDatepicker,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'ContractDate',
  components: {
    BFormGroup,
    BFormDatepicker,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
