<template>
  <div>
    <b-table
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(brand)="data">
        <div class="text-warning font-small-2">
          {{ data.item.arackodu }}
        </div>
        {{ data.item.brand }} {{ data.item.model }}
        <div class="text-primary font-small-2">
          {{ data.item.title }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.fuel }} {{ data.item.gear }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.licence_plate }}
        </div>
      </template>
    </b-table>
  </div>
</template>

<script>
import {
  BTable,
} from 'bootstrap-vue'

export default {
  name: 'RentalStock',
  components: {
    BTable,
  },
  data() {
    return {
      fields: [
        {
          key: 'brand',
          label: 'ARAÇ BİLGİSİ',
        },
      ],
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalStocks/dataList']
    },
  },
}
</script>
