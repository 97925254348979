<template>
  <div>
    <b-card-body>
      <b-form-group
        label="Araç Kartı Ara"
        label-for="keyword"
      >
        <b-input-group>
          <b-form-input
            id="keyword"
            v-model="keyword"
            placeholder="Araç Plaka, Araç Kodu veya Şase..."
            @keydown.enter="keywordSearch"
          />
          <b-input-group-append>
            <b-button
              variant="primary"
              @click="keywordSearch"
            >
              <FeatherIcon icon="SearchIcon" />
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </b-card-body>
    <b-table
      v-if="dataList.length > 0"
      responsive="sm"
      :fields="fields"
      :items="dataList"
      striped
    >
      <template #cell(brand)="data">
        {{ data.item.brand }} {{ data.item.model }}
        <div class="text-primary font-small-2">
          {{ data.item.title }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.fuel }} {{ data.item.gear }}
        </div>
        <div class="text-warning font-small-2">
          {{ data.item.licence_plate }}
        </div>
      </template>
      <template #cell(stock_status)="data">
        <b-badge :variant="data.item.status_variant">
          {{ data.item.stock_status }}
        </b-badge>
      </template>
      <template #cell(control)="data">
        <div class="text-right">
          <b-button
            variant="danger"
            size="sm"
            :disabled="dataItem.id_com_rental_stocks.includes(data.item.id)"
            @click="addStockData(data.item)"
          >
            Sözleşmeye Ekle
          </b-button>
        </div>
      </template>
    </b-table>
    <b-card-footer v-if="dataList.length > 0">
      <b-pagination
        v-model="currentPage"
        :total-rows="dataCounts"
        :per-page="perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
      >
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18"
          />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18"
          />
        </template>
      </b-pagination>
    </b-card-footer>
  </div>
</template>

<script>
import {
  BFormGroup,
  BFormInput,
  BTable,
  BPagination,
  BCardBody,
  BCardFooter,
  BBadge,
  BButton,
  BInputGroup,
  BInputGroupAppend,
} from 'bootstrap-vue'

const tableName = 'com_rental_stocks'
export default {
  name: 'StockSearch',
  components: {
    BFormGroup,
    BFormInput,
    BTable,
    BPagination,
    BCardBody,
    BCardFooter,
    BBadge,
    BButton,
    BInputGroup,
    BInputGroupAppend,
  },
  data() {
    return {
      keyword: '',
      currentPage: 1,
      perPage: 10,
      fields: [
        {
          key: 'arackodu',
          label: 'ARAÇ KODU',
          thClass: 'width-150',
        },
        {
          key: 'brand',
          label: 'ARAÇ BİLGİSİ',
        },
        {
          key: 'stock_status',
          label: 'DURUM',
        },
        {
          key: 'control',
          label: '',
          thStyle: { width: '200px' },
        },
      ],
      dataQuery: {
        select: [
          `${tableName}.id AS id`,
          `${tableName}.arackodu AS arackodu`,
          `${tableName}.title AS title`,
          `${tableName}.licence_plate AS licence_plate`,
          'com_swap_brand.name AS brand',
          'com_swap_model.title AS model',
          'com_fuel.title AS fuel',
          'com_gear.title AS gear',
          'com_rent_stock_status.title AS stock_status',
          'com_rent_stock_status.variant AS status_variant',
          `${tableName}.id_com_rent_stock_status AS id_com_rent_stock_status`,
        ],
        where: {
          'com_rent_stock_status.id': 1,
        },
        limit: 10,
        start: 0,
      },
    }
  },
  computed: {
    dataList() {
      return this.$store.getters['rentalStocks/dataList']
    },
    dataCounts() {
      return this.$store.getters['rentalStocks/dataCounts']
    },
    dataItem() {
      return this.$store.getters['rentalContracts/dataItem']
    },
  },
  watch: {
    currentPage(val) {
      this.dataQuery.start = (val - 1) * 10
      this.getDataList()
    },
  },
  created() {
    this.$store.commit('rentalStocks/SET_DATA_LIST', [])
  },
  methods: {
    addStockData(data) {
      this.dataItem.id_com_rental_stocks.push(data.id)
      this.dataItem.rental_stocks.push(data)
    },
    keywordSearch() {
      if (this.keyword) {
        this.dataQuery.or_like = {
          'com_rental_stocks.arackodu': this.keyword,
          'com_rental_stocks.licence_plate': this.keyword,
          'com_rental_stocks.chasis': this.keyword,
        }
        this.getDataList()
      } else {
        this.$store.commit('rentalStocks/SET_DATA_LIST', [])
      }
    },
    getDataList() {
      this.$store.dispatch('rentalStocks/getDataList', this.dataQuery)
    },
  },
}
</script>
